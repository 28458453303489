#brief-form {
    .spin-general {
        position: fixed;
    }

    .form-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 24px;

        .card {
            background-color: $white;
            border-radius: 2px;

            .header {
                padding: 16px 24px;
                border-bottom: 1px solid $light-gray;
                font-size: $h4-font-size;
            }

            .body {
                padding: 24px 48px;
            }
        }

        .general-info {
            .body {
                display: flex;
                flex-wrap: wrap;
                gap: 0 24px;

                .ant-form-item {
                    width: calc(100% * (1 / 3) - 24px);
                }

                .ant-picker-range {
                    width: 100%;
                }

                .ant-form-item-tooltip {
                    position: absolute;
                    right: 0;
                }

                .form-tooltip {
                    .ant-form-item-label > label {
                        width: 100%;
                    }
                }
            }
        }

        .objetive-public {
            .body {
                display: flex;
                flex-wrap: wrap;
                gap: 0 24px;

                .ant-form-item {
                    width: calc(100% * (1 / 3) - 24px);
                }

                .upload-list-inline .ant-upload-list-item {
                    float: left;
                    width: 200px;
                    margin-right: 8px;
                }

                .upload-list-inline
                    [class*='-upload-list-rtl']
                    .ant-upload-list-item {
                    float: right;
                }

                .form-select-multiple {
                    width: calc(100% * (1 / 3) - 24px);
                }

                .form-textarea {
                    width: calc(100% * (1 / 1.5) - 24px);
                }
            }
        }

        .campaign-specification {
            .body {
                display: flex;
                flex-direction: column;
                gap: 0 24px;

                .slider-brief-container {
                    width: calc(100% * (1 / 1.5) - 24px);

                    .title {
                        display: flex;
                        gap: 16px;
                    }

                    .body {
                        padding: 48px 24px 24px 24px;
                        margin: 8px 0 24px 0;
                        border: 1px solid $preview-border;
                        display: flex;
                        flex-direction: column;
                        gap: 50px;

                        .ant-form-item-label {
                            max-width: 130px;
                            width: 100%;
                            text-align: end;
                        }
                        .ant-form-item {
                            flex-direction: row;
                            width: 100%;
                            align-items: center;
                            gap: 5px;
                            margin-bottom: 0;
                        }
                    }
                }

                .ant-form-item {
                    width: calc(100% * (1 / 3) - 24px);
                }

                .form-textarea {
                    width: calc(100% * (1 / 1.5) - 24px);
                }

                .form-checkbox {
                    width: 100%;
                }
            }
        }

        .buttons-footer {
            margin-bottom: 24px;
            padding: 24px;
        }
    }
}

@include sm-max {
    #brief-form {
        .form-container {
            .general-info {
                .body {
                    .ant-form-item {
                        width: calc(100% * (1 / 2) - 24px);
                    }
                }
            }

            .objetive-public {
                .body {
                    .ant-form-item {
                        width: calc(100% * (1 / 2) - 24px);
                    }

                    .form-select-multiple {
                        width: 100%;
                    }

                    .form-textarea,
                    .slider-brief-container {
                        width: 100%;
                    }
                }
            }

            .campaign-specification {
                .body {
                    .ant-form-item {
                        width: calc(100% * (1 / 2) - 24px);
                    }

                    .form-textarea,
                    .slider-brief-container,
                    .form-checkbox {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@include xs-max {
    #brief-form {
        .form-container {
            .general-info {
                .body {
                    .ant-form-item {
                        width: 100%;
                        // flex-direction: row;
                    }
                }
            }

            .objetive-public {
                .body {
                    .ant-form-item {
                        width: 100%;
                        // flex-direction: row;
                    }

                    .form-textarea,
                    .slider-brief-container,
                    .form-checkbox {
                        width: 100%;
                        // flex-direction: row;
                    }
                }
            }

            .campaign-specification {
                .body {
                    .ant-form-item {
                        width: 100%;
                        // flex-direction: row;
                    }

                    .form-textarea,
                    .slider-brief-container {
                        width: 100%;
                        // flex-direction: row;
                    }
                }
            }
        }
    }
}
