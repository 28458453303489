body {

    //     .ant-form-item-label>label {
    //         color: $gray-dark;
    //     }

    //     a:focus,
    //     a:hover,
    //     a:active,
    //     .ant-table-cell-header-icon-sort,
    //     .ant-table-cell-header-icon-sort-desc::after,
    //     .ant-table-cell-header-icon-sort-asc::after,
    //     .ant-picker-select-menu-item.ant-picker-select-menu-item-active,
    //     .ant-picker-select-menu-item.ant-picker-select-menu-item-active:hover,
    //     .ant-btn-link:not(.ant-nav-item-content):hover,
    //     .ant-btn-link:not(.ant-nav-item-content):focus,
    //     .ant-pagination>li.ant-pagination-btn-active>a:active {
    //         color: $info-hover;
    //     }

    //     a,
    //     .ant-nav-item-content,
    //     .ant-btn-link:not(.ant-nav-item-content),
    //     .ant-pagination>li.ant-pagination-btn-active>a {
    //         color: $info;
    //     }

    //     .mfb-component__button--child:hover,
    //     .mfb-component__button--child:focus,
    //     .mfb-component__button--child:active,
    //     .mfb-component__button--main:hover,
    //     .mfb-component__button--main:focus,
    //     .mfb-component__button--main:active,
    //     .mfb-component__button--main .mfb-component__main-icon--active:hover,
    //     .btn-primary,
    //     .ant-btn-primary,
    //     .ant-btn-primary:active:hover,
    //     .ant-btn-primary:active:focus,
    //     .ant-btn-primary:active:active,
    //     .ant-btn-primary .ant-picker-toolbar-right-btn-ok,
    //     .ant-btn-primary.ant-btn-active,
    //     .ant-btn-primary.ant-btn-active:hover,
    //     .ant-open>.ant-dropdown-toggle.ant-btn-primary:hover,
    //     .ant-btn-primary.ant-btn-active:focus,
    //     .ant-open>.ant-dropdown-toggle.ant-btn-primary:focus,
    //     .ant-btn-primary:active.focus,
    //     .ant-btn-primary.ant-btn-active.focus,
    //     .ant-open>.ant-dropdown-toggle.ant-btn-primary.focus,
    //     .ant-btn-default.primary,
    //     .ant-btn-default.primary:hover,
    //     .ant-btn-default.primary:focus,
    //     .ant-btn-default.primary:active,
    //     .mfb-component__button--child,
    //     .mfb-component__button--main,
    //     .ant-btn-default.primary.ant-btn-disabled,
    //     .ant-picker-toolbar-right-btn-ok.ant-picker-toolbar-btn-disabled,
    //     .ant-btn-primary.ant-btn-disabled:hover {
    //         color: $deep-purple !important;
    //     }

    //     .ant-btn-primary,
    //     .ant-btn.primary,
    //     .ant-picker-toolbar-right-btn-ok,
    //     .ant-btn-primary.ant-btn-active,
    //     .ant-picker-value-count,
    .ant-progress-bg,
    .ant-btn-toggle-checked:not(.availability) {
        background-color: $primary !important;
        border-color: $primary !important;
    }

    .ant-progress-status-exception .ant-progress-bg {
        background-color: $danger !important;
    }

    .ant-progress-status-success .ant-progress-bg {
        background-color: $success !important;
    }

    //     .ant-btn-primary:active:hover,
    //     .ant-btn-primary.ant-btn-active:hover,
    //     .ant-open>.ant-dropdown-toggle.ant-btn-primary:hover,
    //     .ant-btn-primary:active:focus,
    //     .ant-btn-primary.ant-btn-active:focus,
    //     .ant-open>.ant-dropdown-toggle.ant-btn-primary:focus,
    //     .ant-btn-primary:active.focus,
    //     .ant-btn-primary.ant-btn-active.focus,
    //     .ant-open>.ant-dropdown-toggle.ant-btn-primary.focus,
    //     .ant-btn-default.primary:hover,
    //     .ant-btn-default.primary:focus,
    //     .ant-btn-default.primary:active,
    //     .ant-picker-toolbar-right-btn-ok:not(.ant-btn-disabled):hover,
    //     .ant-nav-subtle.ant-nav-horizontal .ant-nav-item>.ant-nav-item-content::before,
    //     .ant-btn-toggle-checked:hover {
    //         background-color: $primary-color-active !important;
    //         border-color: $primary-color-active !important;
    //     }

    //     .ant-btn-default.primary.ant-btn-disabled,
    //     .ant-picker-toolbar-right-btn-ok.ant-picker-toolbar-btn-disabled,
    //     .ant-btn-toggle-disabled.ant-btn-toggle-checked,
    //     .ant-btn-toggle-disabled.ant-btn-toggle-checked:hover,
    //     .ant-btn-primary.ant-btn-disabled:hover {
    //         background-color: $primary-light-color !important;
    // border-color: $primary-light-color !important;
    //     }

    //     .ant-btn-primary.ant-btn-disabled:hover,
    //     .ant-btn-primary.ant-btn-disabled:focus,
    //     .ant-btn-primary.ant-btn-disabled.focus {
    //         background: $primary !important;
    //         border-color: $primary !important;
    //     }

    //     .ant-btn-primary:not(.ant-btn-disabled):hover {
    //         color: $deep-purple;
    //         background-color: $primary-color-hover !important;
    //         border-color: $primary-color-hover !important;
    //     }

    //     .ant-btn-background-ghost {
    //         color: $primary;
    //         border: 1px solid $primary;
    //     }

    //     .ant-btn-background-ghost:hover {
    //         color: $primary-color-hover;
    //         border-color: $primary-color-hover;
    //     }

    //     .ant-input:focus,
    //     .ant-input:hover,
    //     .ant-picker-menu .ant-picker-search-bar .ant-picker-search-bar-input:focus,
    //     .ant-picker-menu .ant-picker-search-bar .ant-picker-search-bar-input:hover,
    //     .ant-checkbox-checker:hover .ant-checkbox-wrapper .ant-checkbox-inner::before,
    //     .ant-calendar-table-cell-is-today .ant-calendar-table-cell-content {
    //         border-color: $primary-color-hover !important;
    //     }

    //     .ant-btn-background-ghost:active:hover,
    //     .ant-btn-background-ghost.ant-btn-active:hover,
    //     .ant-open>.ant-dropdown-toggle.ant-btn-background-ghost:hover,
    //     .ant-btn-background-ghost:active:focus,
    //     .ant-btn-background-ghost.ant-btn-active:focus,
    //     .ant-open>.ant-dropdown-toggle.ant-btn-background-ghost:focus,
    //     .ant-btn-background-ghost:active.ant-btn-focus,
    //     .ant-btn-background-ghost.ant-btn-active.ant-btn-focus,
    //     .ant-open>.ant-dropdown-toggle.ant-btn-background-ghost.ant-btn-focus {
    //         color: $primary-color-active;
    //         border-color: $primary-color-active;
    //     }

    //     .ant-checkbox-checked .ant-checkbox-wrapper .ant-checkbox-inner::before,
    //     .ant-checkbox-indeterminate .ant-checkbox-wrapper .ant-checkbox-inner::before {
    //         border-color: $primary !important;
    //         background-color: $primary;
    //         @include box-shadow('0 0 0 2px rgb(102 204 204 / 40%)');
    //     }

    //     .ant-avatar-circle,
    //     .circle-avatar {
    //         font-weight: $font-weight-semi-bold;
    //         color: $black;
    //         background-color: $primary;
    //     }

    //     .ant-calendar-table-cell-selected .ant-calendar-table-cell-content {
    //         color: $black;
    //         border-color: $primary;
    //         background-color: $primary;
    //     }

    //     a.ant-nav-item-content,
    //     .ant-nav-item-content:active {
    //         color: $availabl-disable-color !important;
    //     }

    .ant-uploader-draggable .ant-uploader-trigger.ant-uploader-trigger-customize:not(.ant-uploader-trigger-disabled) .ant-uploader-trigger-btn:hover,
    .ant-uploader-draggable .ant-uploader-trigger-drag-over .ant-uploader-trigger-btn.ant-uploader-trigger-customize {
        border-color: $primary-color-hover;
    }
}