#advertiser-list {
    background-color: $white;
    @include border-radius(2px);
    min-height: calc(100vh - 96px);
    max-height: calc(100vh - 96px);
    padding: 24px;
    @include flexbox();
    flex-direction: column;

    .header {
        @include flexbox();
        @include justify-content(space-between);
        @include align-items(center);

        .header-title {
            font-size: calculateRem(20px);
            font-weight: $font-weight-semi-bold;
        }
    }

    .body {
        .body-title {
            font-size: $h4-font-size;
            margin: 16px 0;
        }

        .body-table {
            .brief-table {
                .estado {
                    border: 1px solid transparent;
                    @include border-radius(2px);
                    padding: 0 8px;
                    text-align: center;
                    width: max-content;
                }
            }
        }
    }
}
