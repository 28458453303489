#send-proposal {
    @include flexbox();
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;

    .proposal-tabs {
        padding: 24px 24px 0 24px;
        background-color: $white;
        @include border-radius(2px);
        @include flexbox();
        @include justify-content(space-between);
        position: relative;

        .tabs {
            .ant-tabs-nav {
                margin: 0;

                .ant-tabs-tab {
                    font-size: 16px;
                }
            }

            .ant-tabs-nav::before {
                border-bottom: none;
            }
        }

        .right-side {
            position: absolute;
            margin-right: 14px;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);

            @include flexbox();

            .item-right-side {
                border-left: 1px solid $light-gray;
                padding: 0 10px;
                align-self: flex-end;

                .title {
                    font-size: $h5-font-size;
                    color: $gray;
                    margin-bottom: 5px;
                }

                .visible-not {
                    visibility: hidden;
                }

                .estado {
                    border: 1px solid transparent;
                    @include border-radius(2px);
                    padding: 0 8px;
                    text-align: center;
                    width: max-content;
                    height: 24px;
                    display: flex;
                    align-items: center;
                }

                .primary-dropdown {
                    @include flexbox();
                    @include justify-content(center);
                    @include align-items(center);

                    button:first-child {
                        border-radius: 2px 0 0 2px;
                    }

                    button:last-child {
                        border-radius: 0 2px 2px 0;
                    }
                }
            }
        }
    }
    .body-container {
        background-color: $white;
        @include border-radius(2px);
        padding: 24px;

        .assets-list-container {
            .quantity {
                text-align: end;
            }

            .summary-table {
                background: $primary-hover-notification !important;
                .ant-table-cell-fix-left,
                .ant-table-cell-fix-right {
                    background: $primary-hover-notification !important;
                }
                .summary-table-cant {
                    text-align: end;
                }
                span {
                    font-weight: $font-weight-semi-bold;
                    font-size: $h4-font-size;
                }
            }
        }
        .strategy-container {
            .container {
                border: 1px solid $light-gray;
                padding: 24px;
                width: 100%;

                font-size: $h4-font-size;
                color: $gray;

                span {
                    text-align: center;
                }
            }
        }

        .map-container {
            border: 1px solid $light-gray;

            @include flexbox();
            .map {
                min-height: 570px;
                height: 100%;
                margin: 16px;
                width: 100%;
            }
            .reference {
                margin: 16px;
                width: 100%;
                max-width: 300px;
                min-width: 220px;

                .title {
                    font-size: $h4-font-size;
                }

                .body {
                    margin-top: 16px;
                    color: $black;
                    overflow-x: hidden;
                    overflow: auto;
                    max-height: 535px;

                    .empty-layers {
                        color: $gray;
                    }

                    .ant-collapse .ant-collapse-item {
                        .ant-collapse-header {
                            padding: 0;
                            color: $black;

                            span {
                                padding: 10px 0;
                                left: 0;
                                font-size: $h5-font-size;
                                margin-right: 8px;
                            }
                        }

                        .ant-collapse-content-box {
                            padding: 2px 16px 12px 0;
                        }
                    }

                    .item {
                        width: 100%;
                        @include flexbox();
                        @include align-items(center);
                        @include justify-content(space-between);
                        color: $gray-dark-2;
                        padding: 8px 0;

                        .left {
                            @include flexbox();
                            gap: 8px;
                            .icon {
                                width: 12px;
                            }

                            .material {
                                height: 12px;
                                border-radius: 100%;
                                align-self: center;
                            }

                            .geopath {
                                height: 12px;
                                img {
                                    vertical-align: unset;
                                }
                            }

                            .assets-types {
                                width: 32px;
                                padding: 2px 5px;
                                border: 1px solid $preview-border;
                                font-weight: $font-weight-semi-bold;
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }
        }
        .abstract-container {
            .container {
                border: 1px solid $light-gray;
                padding: 24px;
                @include flexbox();
                @include justify-content(space-between);
                gap: 10px;
                width: 100%;

                .logo img {
                    width: 40px;
                    height: 40px;
                    border-radius: 2px;
                }

                .info {
                    @include flexbox();
                    @include align-items(center);
                    word-break: break-word;
                    flex-wrap: wrap;
                    width: 100%;

                    .info-item {
                        position: relative;
                        padding: 0 16px;
                        margin-bottom: 16px;

                        .divider {
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 100%;
                            border-right: 1px solid $light-gray;
                        }

                        .title {
                            font-size: $h5-font-size;
                            color: $gray;
                            margin-bottom: 7px;
                        }

                        .text {
                            font-size: $h4-font-size;
                            color: $gray-dark;
                            width: 100%;
                            font-weight: $font-weight-semi-bold;
                        }
                    }
                }
            }
            .totals-container {
                border-bottom: 1px solid $light-gray;
                border-left: 1px solid $light-gray;
                border-right: 1px solid $light-gray;
                padding: 24px;

                .totals {
                    width: 30%;
                    min-width: 240px;
                    margin: auto;

                    .section {
                        padding: 16px 24px;
                        @include flexbox();
                        flex-direction: column;
                        gap: 10px;
                        .section-item {
                            @include flexbox();
                            @include justify-content(space-between);
                            @include align-items(center);

                            .title {
                                font-size: $h5-font-size;
                                color: $gray;
                            }
                            .value {
                                color: $gray-dark;
                            }
                            .title-black,
                            .value-black,
                            .value-strong {
                                color: $gray-dark;
                                font-weight: $font-weight-semi-bold;
                            }
                            .value-strong {
                                font-size: $h1-font-size;
                            }

                            .input {
                                max-width: 100px;
                                min-width: 90px;

                                .ant-form-item {
                                    margin-bottom: 0;
                                    .ant-form-item-label {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                    .section:nth-child(1) {
                        border: 1px solid $light-gray;
                        gap: 8px;
                    }
                    .section:nth-child(2) {
                        border-left: 1px solid $light-gray;
                        border-right: 1px solid $light-gray;
                        gap: 14px;
                    }
                    .section:nth-child(3) {
                        background-color: $primary-hover-notification;
                        border-top: 1px solid $campaign-color;
                        border-bottom: 1px solid $light-gray;
                        border-left: 1px solid $light-gray;
                        border-right: 1px solid $light-gray;
                    }
                }
            }
        }
    }
}
