#search-create-advertiser {
    background-color: $white;
    @include border-radius(2px);
    min-height: calc(100vh - 178px);
    @include flexbox();
    flex-direction: column;
    position: relative;

    .header {
        padding: 16px 24px;
        border-bottom: 1px solid $light-gray;
        @include flexbox();
        gap: 16px;
        @include align-items(center);
        .title {
            font-size: $h4-font-size;
            font-weight: $font-weight-semi-bold;
        }
    }

    .body {
        padding: 16px 24px;
        @include flexbox();
        flex-direction: column;
        gap: 24px;
        .text {
            color: $gray;
            font-size: $h5-font-size;
            max-width: 391px;
            line-height: 1.5;
        }

        .search-advertiser {
            .form-body {
                @include flexbox();
                gap: 8px;
            }
            .ant-form-item {
                margin-bottom: 0;
                width: 100%;
                min-width: 150px;
                max-width: 300px;
            }
        }

        .create-advertiser {
            font-size: $h5-font-size;
            .anticon {
                color: $gray-dark-2;
            }
        }
        .create-advertiser:hover {
            .anticon {
                color: $info-hover;
            }
        }
    }

    .footer {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 24px 24px;
    }
}
