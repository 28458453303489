#vendors-list {
    background-color: $white;
    @include border-radius(2px);
    padding: 24px;
    @include flexbox();
    flex-direction: column;

    .header {
        @include flexbox();
        @include justify-content(space-between);
        @include align-items(center);

        .header-title {
            font-size: calculateRem(20px);
            font-weight: $font-weight-semi-bold;
        }

        .header-button {
            display: flex;
            gap: 16px;
        }
    }

    .body {
        margin-top: 32px;
        @include flexbox();
        // @include justify-content(space-between);
        flex-direction: row;
        flex-wrap: wrap;
        gap: 24px;
        @include align-items(stretch);
        width: 100%;

        .vendor-item {
            background-color: $account-gray;
            border: 1px solid $account-gray-border;
            border-radius: 2px;
            @include flexbox();
            flex-direction: column;
            width: calc(100% * (1/3) - 24px);

            .vendor-header {
                padding: 12px 24px;
                border-bottom: 1px solid $account-gray-border;
                @include flexbox();
                @include align-items(center);
                gap: 10px;

                .logo img {
                    width: 32px;
                    height: 32px;
                    border-radius: 2px;
                }

                .title {
                    span {
                        font-weight: $font-weight-semi-bold;
                        margin-left: 2px;
                    }
                }
            }

            .vendor-header:hover {
                cursor: pointer;
                background-color: $account-gray-hover;
            }

            .vendor-body {
                padding: 24px;
                @include flexbox();
                flex-direction: column;

                .contacts-list {
                    background-color: $white;
                    padding: 15px 24px 0 24px;
                    height: 210px;

                    .title {
                        font-size: $h4-font-size;
                        margin-bottom: 16px
                    }

                    .slick-dots {
                        li button {
                            background: $light-gray;
                            opacity: 1;
                        }

                        .slick-active button {
                            background: $gray-hover;
                            opacity: 1;
                        }
                    }

                    .contact {

                        height: 160px;

                        .space {
                            @include flexbox();
                            @include align-items(center);
                            gap: 3px;
                            padding: 8px 0;

                            span {
                                color: $gray;
                            }
                        }

                    }
                }
            }
        }

        .vendor-item:nth-of-type(3n) {
            margin-right: 0;
        }

        .vendor-item:nth-of-type(3n+1) {
            margin-left: 0;
        }

    }
}

@include sm-max {
    #vendors-list {

        .body {
            .vendor-item {
                width: calc(100% * (1/2) - 24px);
            }
        }
    }
}

@include xs-max {
    #vendors-list {

        .body {
            .vendor-item {
                width: 100%;
            }
        }
    }
}