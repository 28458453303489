.ant-dropdown-menu-item.active {
    background-color: $blue-1;

    a {
        color: $gray-dark;
    }
}

.ant-dropdown-menu-item.inactive a {
    color: $gray;
}

.notificationPanel {
    max-width: 350px;
    min-width: 300px;
    background-color: $white;
    background-clip: padding-box;
    @include border-radius(2px);
    outline: none;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
        0 9px 28px 8px rgb(0 0 0 / 5%);

    .header {
        padding: 12px 24px;
        border-bottom: 1px solid $light-gray;
    }

    .body {
        .notification-item {
            border-bottom: 1px solid $light-gray;
            @include flexbox();
            flex-direction: row;
            @include justify-content(flex-start);
            @include align-items(flex-start);
            padding: 12px 24px;

            .type {
                width: 32px;
                min-width: 32px;
                height: 32px;
                background-color: $primary;
                @include border-radius(100%);
                @include flexbox();
                @include justify-content(center);
                @include align-items(center);
                margin-right: 16px;

                .icon-navbar {
                    font-size: $h4-font-size;
                    color: $black;
                }
            }

            .text {
                .title,
                .sub-title {
                    color: $gray-dark;
                }

                .description {
                    @include align-items(flex-end);
                    margin-top: 12px;
                    color: $gray;
                    font-size: $h6-font-size;
                }
            }
        }

        .notification-item:hover {
            background-color: $primary-hover-notification;
            cursor: pointer;
        }
    }

    .footer {
        height: 48px;
        @include flexbox();
        @include justify-content(flex-end);
        @include align-items(center);

        .delete {
            @include flexbox();
            @include justify-content(center);
            @include align-items(center);
            border-left: 1px solid $light-gray;
            width: 50%;
            height: 100%;
        }

        .delete:hover {
            background-color: $hover-menu;
            cursor: pointer;
        }
    }
}
