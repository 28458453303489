#advertiser-detail {
    @include flexbox();
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;

    .container-top {
        @include flexbox();
        gap: 24px;
    }

    .account {
        width: 30%;

        .body {
            background-color: $white;
            margin: 24px;
            padding: 24px;

            .confirmation-data {
                @include flexbox();
                flex-direction: column;
                gap: 24px;
                .description {
                    font-size: $h4-font-size;
                    font-weight: $font-weight-semi-bold;
                }
                .logo-name {
                    @include flexbox();
                    flex-direction: column;
                    gap: 4px;
                }

                .title-description {
                    @include flexbox();
                    flex-direction: column;
                    gap: 4px;
                    .title {
                        font-size: $h5-font-size;
                        color: $gray;
                    }
                }
            }
        }
    }

    .brand {
        width: 70%;
        position: relative;

        .body {
            margin: 24px;

            .brand-container {
                @include flexbox();
                @include align-items(center);
                gap: 16px;
                flex-wrap: wrap;
                max-height: 300px;
                overflow: auto;

                .brand-item {
                    padding: 24px;
                    background-color: $white;
                    width: calc(100% * (1 / 3) - 24px);

                    @include flexbox();
                    flex-direction: column;
                    gap: 4px;

                    .description {
                        font-size: $h4-font-size;
                        font-weight: $font-weight-semi-bold;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }

    .company-names {
        .body {
            margin: 24px 24px 8px 24px;
        }
    }

    .contact {
        .body {
            margin: 24px 24px 8px 24px;
        }
    }

    .box {
        background-color: $account-gray;
        border: 1px solid $account-gray-border;

        .header {
            padding: 24px;
            border-bottom: 1px solid $account-gray-border;
            @include flexbox();
            @include justify-content(space-between);
            font-size: $h4-font-size;
        }
    }
}
