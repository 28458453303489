#proposal-detail {
    @include flexbox();
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;

    .proposal-tabs {
        padding: 24px 24px 0 24px;
        background-color: $white;
        @include border-radius(2px);
        @include flexbox();
        @include justify-content(space-between);
        position: relative;

        .tabs {
            .ant-tabs-nav {
                margin: 0;

                .ant-tabs-tab {
                    font-size: 16px;
                }
            }

            .ant-tabs-nav::before {
                border-bottom: none
            }
        }

        .right-side {
            position: absolute;

            right: 0;

            top: 50%;

            transform: translate(0, -50%);

            @include flexbox();

            .item-right-side {
                border-left: 1px solid $light-gray;
                padding: 0 10px;
                align-self: flex-end;

                .title {
                    font-size: $h5-font-size;
                    color: $gray;
                    margin-bottom: 5px;
                }

                .visible-not {
                    visibility: hidden;
                }

                .estado {
                    border: 1px solid transparent;
                    @include border-radius(2px);
                    padding: 0 8px;
                    text-align: center;
                    width: max-content;
                    height: 24px;
                    display: flex;
                    align-items: center;
                }

                .primary-dropdown {
                    @include flexbox();
                    @include justify-content(center);
                    @include align-items(center);

                    button:first-child {
                        border-radius: 2px 0 0 2px;
                    }

                    button:last-child {
                        border-radius: 0 2px 2px 0;
                    }

                }
            }
        }
    }

    .box {
        background-color: $white;
        @include border-radius(2px);
        padding: 24px;



        .container {
            @include flexbox();
            @include justify-content(space-between);
            gap: 10px;
            width: 100%;

            .logo img {
                width: 40px;
                height: 40px;
                border-radius: 2px;
            }

            .info {
                @include flexbox();
                @include align-items(center);
                word-break: break-word;
                flex-wrap: wrap;
                width: 100%;

                .info-item {
                    position: relative;
                    padding: 0 16px;
                    margin-bottom: 16px;

                    .divider {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        border-right: 1px solid $light-gray;
                    }

                    .title {
                        font-size: $h5-font-size;
                        color: $gray;
                        margin-bottom: 7px;
                    }

                    .text {
                        font-size: $h4-font-size;
                        color: $gray-dark;
                        width: 100%;
                        font-weight: $font-weight-semi-bold;

                        .range-picker-proposal {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .button-collapse {
                button span {
                    font-size: 8px;
                    @include flexbox();
                    @include align-items(center);
                    @include justify-content(center);

                    transition: all 333ms ease-in-out, color 333ms;
                }

                button span.open {
                    transform: rotate(-180deg);
                }
            }
        }


        .container-collapse.hidden {
            max-height: 0;
            border-top: 0px solid $light-gray;
        }

        .container-collapse.show {
            max-height: 1000px;
        }

        .container-collapse {
            overflow: hidden;
            transition: all 0.8s ease-in-out;
            // border-top: 1px solid $light-gray;
            background-color: $account-gray;


            // padding: 17px 24px;

            .public-objetives,
            .campaign-specifications {
                @include flexbox();
                gap: 10px;
                width: 100%;
                padding: 17px 24px;
                border-top: 1px solid #E8E8E8;

                >.title {
                    white-space: nowrap;
                }


                .info {
                    @include flexbox();
                    @include align-items(center);
                    flex-wrap: wrap;
                    word-break: break-word;

                    .info-item {
                        position: relative;
                        padding: 0 16px;
                        margin-bottom: 16px;

                        .divider {
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 100%;
                            border-right: 1px solid $light-gray;
                        }

                        .title {
                            font-size: $h5-font-size;
                            color: $gray;
                            margin-bottom: 7px;
                        }

                        .text {
                            font-size: $h5-font-size;
                            color: $gray-dark;
                            // font-weight: $font-weight-semi-bold;
                        }
                    }
                }
            }
        }
    }


}