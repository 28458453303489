#activity-list {
    @include flexbox();
    flex-direction: column;
    gap: 24px;

    .header,
    .body-activity,
    .body-filters {
        background-color: $white;
        @include border-radius(2px);
        padding: 24px;
        @include flexbox();
        flex-direction: column;

    }

    .header {
    padding: 24px 24px 0 24px;

    .tabs-activity {
        .ant-tabs-nav {
            margin: 0;

            .ant-tabs-tab {
                font-size: 16px;
            }
        }

        .ant-tabs-nav::before {
            border-bottom: none
        }
    }
}

    .body-filters {
        .filter-activity {

            #form-activity {
                .form-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .form-body {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 0 8px;
                        align-items: flex-end;
                        width: 100%;
                        justify-content: space-evenly;

                        .ant-form-item {
                            // width: calc(100% * (1/3) - 24px);
                            min-width: 250px;
                            margin-bottom: 0;

                            .ant-form-item-label {
                                padding: 0;

                                label {
                                    height: 30px;
                                }
                            }

                            .ant-form-item-control {
                                .ant-form-item-control-input {

                                    min-height: 24px;
                                }

                                .ant-form-item-control-input-content {
                                    width: 250px;
                                }
                            }
                        }

                        .ant-picker-range {
                            // width: 100%;
                            min-width: 250px;

                        }

                        .ant-form-item-tooltip {
                            position: absolute;
                            right: 0;
                        }

                        .form-tooltip {
                            .ant-form-item-label>label {
                                width: 100%;
                            }
                        }
                    }

                    .buttons {
                        display: flex;
                        align-items: center;
                        flex-direction: row;

                        .separador-vertical {
                            height: 24px;
                            width: 1px;
                            background-color: $light-gray;
                            margin-left: 8px;
                            margin-right: 16px
                        }


                    }
                }
            }
        }
    }

    .title-box {
        @include flexbox();
        @include justify-content(space-between);
        @include align-items(center);
        margin-bottom: 14px;

        .header-title {
            font-size: calculateRem(20px);
            font-weight: $font-weight-semi-bold;
        }

        .header-button {
            display: flex;
            gap: 16px;
        }
    }

    .body {
        margin-top: 32px;
        @include flexbox();
        // @include justify-content(space-between);
        flex-direction: row;
        flex-wrap: wrap;
        gap: 24px;
        @include align-items(stretch);
        width: 100%;

        .activity-item {
            background-color: $account-gray;
            border: 1px solid $account-gray-border;
            border-radius: 2px;
            @include flexbox();
            flex-direction: column;
            width: calc(100% * (1/3) - 24px);

            .activity-header {
                padding: 12px 24px;
                border-bottom: 1px solid $account-gray-border;
                @include flexbox();
                @include align-items(center);
                gap: 10px;

                .logo img {
                    width: 32px;
                    height: 32px;
                    border-radius: 2px;
                }

                .title {
                    width: 100%;

                    span {
                        font-weight: $font-weight-semi-bold;
                        margin-left: 2px;
                    }
                }

                .botton-options {
                    display: flex;
                    width: 100%;
                    justify-content: flex-end;
                    // cursor: pointer;
                }
            }

            // .activity-header:hover {
            //     cursor: pointer;
            //     background-color: $account-gray-hover;
            // }

            .activity-body {
                padding: 24px;
                @include flexbox();
                height: 100%;
                flex-direction: column;

                .activity {
                    background-color: $white;
                    padding: 15px 24px;
                    @include flexbox();
                    height: 100%;
                    flex-direction: column;
                    gap: 16px;

                    .text {
                        display: inline-block;
                        margin-left: 5px;
                    }

                    .text-ellipsis {
                        display: block;
                        margin-left: 5px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                    }

                    .type {
                        @include flexbox();
                        @include align-items(center);
                        @include justify-content(space-between);

                        .activity-type {
                            @include flexbox();
                            align-self: flex-start;
                            @include align-items(center);
                            width: 50%;
                        }

                        .activity-date {
                            @include flexbox();
                            flex-direction: column;
                            @include justify-content(start);
                            gap: 5px;
                            white-space: nowrap;

                            svg {
                                color: $gray;
                            }
                        }
                    }

                    .description,
                    .worker {

                        .label {
                            color: $gray;
                            margin: 5px 0;
                        }
                    }

                    .worker-link {
                        width: fit-content;
                        color: $info;
                        cursor: pointer;
                    }

                    .worker-link:hover {
                        color: $info-hover;
                    }

                    .title {
                        font-size: $h4-font-size;
                        margin-bottom: 16px
                    }

                    .space {
                        @include flexbox();
                        @include align-items(center);
                        gap: 3px;
                        padding: 8px 0;

                        span {
                            color: $gray;
                        }
                    }


                }


            }
        }

        .activity-item:nth-of-type(3n) {
            margin-right: 0;
        }

        .activity-item:nth-of-type(3n+1) {
            margin-left: 0;
        }

    }
}

@include sm-max {
    #activity-list {



        .body {
            .activity-item {
                width: calc(100% * (1/2) - 24px);
            }
        }
    }
}

@include xs-max {
    #activity-list {



        .body {
            .activity-item {
                width: 100%;
            }
        }
    }
}