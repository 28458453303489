#proposal-list {
    @include flexbox();
    flex-direction: column;
    gap: 24px;


    .header,
    .body-proposal,
    .body-filters {
        background-color: $white;
        @include border-radius(2px);
        padding: 24px;
        @include flexbox();
        flex-direction: column;

    }

    .header {
        padding: 24px 24px 0 24px;

        .tabs-proposal {
            .ant-tabs-nav {
                margin: 0;

                .ant-tabs-tab {
                    font-size: $h4-font-size;
                }
            }

            .ant-tabs-nav::before {
                border-bottom: none
            }
        }
    }

    .body-proposal {
        min-height: calc(100vh - 231px);
        max-height: calc(100vh - 231px);

        .body-title {
            font-size: $h4-font-size;
            margin: 16px 0;
        }

        .body-table {
            .proposal-table {
                .estado {
                    border: 1px solid transparent;
                    @include border-radius(2px);
                    padding: 0 8px;
                    text-align: center;
                    width: max-content;
                }
            }
        }
    }



    .title-box {
        @include flexbox();
        @include justify-content(space-between);
        @include align-items(center);
        margin-bottom: 14px;

        .header-title {
            font-size: calculateRem(20px);
            font-weight: $font-weight-semi-bold;
        }

        .header-button {
            @include flexbox();
            gap: 16px;
        }
    }


}

@include sm-max {
    #proposal-list {}
}

@include xs-max {
    #proposal-list {}
}