#contact-form {
    background-color: $account-gray;
    border: 1px solid $account-gray-border;
    @include border-radius(2px);
    @include flexbox();
    flex-direction: column;
    margin-bottom: 24px;


    .header {
        @include flexbox();
        @include justify-content(space-between);
        @include align-items(center);
        padding: 16px 24px;

        border-bottom: 1px solid $account-gray-border;

        .header-title {
            font-size: calculateRem(20px);
            font-weight: $font-weight-semi-bold;
        }

    }

    .collapse-contact {
        margin: 24px;

        .body {
            background-color: $white;
            margin: 8px;

            .body-title {
                font-size: $h4-font-size;
                margin: 16px 0;
            }

            .spin-general {
                position: fixed;
            }

            .form-container {
                display: flex;
                flex-direction: column;
                padding: 24px;

                .form-body {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 0 24px;

                    .ant-form-item {
                        width: calc(100% * (1/3) - 24px);
                    }

                    .ant-picker-range,
                    .ant-picker {
                        width: 100%;
                    }

                    .ant-form-item-tooltip {
                        position: absolute;
                        right: 0;
                    }

                    .form-tooltip {
                        .ant-form-item-label>label {
                            width: 100%;
                        }
                    }

                    .form-textarea {
                        width: calc(100% * (1/1.5) - 24px);
                    }
                }

                .upload-list-inline {
                    .ant-upload-list-item {

                        margin-top: 0;
                    }
                }

            }
        }
    }
}

@include sm-max {
    #contact-form {
        .collapse-contact {

            .body {
                .form-container {
                    .form-body {
                        .ant-form-item {
                            width: calc(100% * (1/2) - 24px);
                        }

                        .form-textarea {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

}

@include xs-max {
    #contact-form {
        .collapse-contact {
            .body {
                .form-container {
                    .form-body {
                        .ant-form-item {
                            width: 100%;
                        }

                        .form-textarea {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

}