#not-found {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 24px;
    gap: 24px;
    text-align: center;

    img {
        margin: 30px;
    }

    .text {
        span {
            font-size: $h5-font-size;
            // font-weight: 400;
        }
    }
}