.assets-list {
    #assets-table {
        .ant-table-container {
            .ant-table-body {
                table {
                    .ant-table-tbody {

                        tr.disabled-row {

                            .ant-table-cell-fix-left,
                            .ant-table-cell-fix-right,
                            .ant-table-cell {
                                background-color: $selected-assets;
                            }
                        }

                    }
                }

            }
        }
    }
}