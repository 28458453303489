#dashboard {
    .metrics {
        @include flexbox();
        gap: 24px;
        flex-direction: column;

        .card {
            background-color: $white;
            border-radius: 2px;
        }

        .metrics-item {
            background-color: $white;
            padding: 20px 24px;
            @include border-radius(2px);

            .separador {
                margin: 10px 0 !important;
            }

            .header {
                @include flexbox();
                @include justify-content(space-between);
                font-size: $h4-font-size;
                color: $gray;

                .info:hover {
                    cursor: pointer;
                    color: $gray-hover;
                }
            }

            .body {
                @include flexbox();
                flex-direction: column;

                .body-title {
                    font-size: calculateRem(30px);
                }
            }

            .footer {
                .footer-text {
                    font-size: $h5-font-size;
                    color: $black;
                }
            }
        }

        .tasks {
            margin-bottom: 24px;

            .separador {
                margin: 0 !important;
            }

            .header {
                padding: 16px 24px;

                .header-title {
                    font-size: $h4-font-size;
                    @include flexbox();
                    height: 40px;
                    @include align-items(center);
                }
            }

            .body {
                padding: 24px;

                .task-list {
                    max-height: 500px;
                    overflow-y: scroll;
                    @include flexbox();
                    flex-direction: column;

                    .task-item {
                        @include flexbox();
                        flex-direction: row;
                        gap: 16px;
                        padding: 16px;
                        border-bottom: 1px solid $light-gray;

                        .indicator {
                            @include flexbox();
                            margin-top: 4px;
                            width: 9px;
                            height: 8px;
                            @include border-radius(50%);
                            border: 1px solid transparent;
                        }

                        .details {
                            @include flexbox();
                            width: 100%;
                            flex-direction: column;

                            .title {
                                font-weight: $font-weight-semi-bold;
                            }

                            .footer {
                                margin-top: 8px;
                                color: $gray;
                            }
                        }

                        .button {
                            @include flexbox();
                            @include justify-content(flex-end);
                            height: 100%;

                            svg {
                                font-size: $h1-font-size;
                            }
                        }
                    }
                }
            }
        }

        .campaigns-table {
            position: relative;
            margin-bottom: 24px;

            .header {
                @include flexbox();
                @include justify-content(space-between);
                @include align-items(center);
                padding: 16px 24px;
                position: sticky;
                top: 0;
                left: 0;
                border-bottom: 1px solid $divider-dashboard;

                .header-title {
                    font-size: $h4-font-size;
                }
            }

            .body {
                padding: 24px;

                .campaign-table {
                    .etapa {
                        span {
                            margin-right: 10px;
                        }

                        svg {
                            color: $primary;
                        }
                    }
                }
            }
        }
    }
}

#title-menu-detail {
    padding: 0 12px;
}

@include xs {
    #dashboard {
        .metrics {
            .tasks {
                .body {
                    .task-list {
                        min-height: calc(100vh - 446px);
                        max-height: calc(100vh - 446px);
                    }
                }
            }
        }
    }
}
