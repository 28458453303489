#add-advertiser {
    background-color: $white;
    @include border-radius(2px);
    min-height: calc(100vh - 178px);
    max-height: calc(100vh - 178px);
    @include flexbox();

    .left-container {
        min-width: 230px;
        width: 20%;
        border-right: 1px solid $light-gray;
        padding: 24px;

        .ant-steps-vertical > .ant-steps-item {
            height: 80px;
        }

        .ant-steps-item-active {
            .ant-steps-item-title {
                font-size: $h4-font-size;
                font-weight: $font-weight-semi-bold;
            }
        }

        .ant-steps-item-error {
            .ant-steps-item-icon {
                border: none;
                @include flexbox();
                @include justify-content(center);
                @include align-items(center);
                .ant-steps-icon {
                    position: relative;
                    margin: auto;
                    width: 6px;
                    height: 6px;
                    @include flexbox();
                    @include border-radius(100px);
                    background-color: $gray-hover;
                    transition: all 0.3s ease 0s;

                    span {
                        display: none;
                    }
                }
            }
            .ant-steps-item-content .ant-steps-item-title {
                color: $gray-dark-2;
            }

            .ant-steps-item-content .ant-steps-item-description {
                display: none;
            }

            .ant-steps-item-tail::after {
                background-color: $info;
            }
        }

        .ant-steps-item-content .ant-steps-item-description {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .right-container {
        width: 80%;
        margin: 24px;
        background-color: $account-gray;
        border: 1px solid $account-gray-border;
        @include flexbox();
        flex-direction: column;

        .header {
            padding: 16px 24px;
            border-bottom: 1px solid $account-gray-border;
            @include flexbox();
            @include align-items(flex-end);
            gap: 16px;

            .title {
                font-size: $h4-font-size;
            }
        }

        .body {
            background-color: $white;
            padding: 24px;
            margin: 24px;
            height: 100%;

            .ant-spin-nested-loading {
                height: 100%;

                .ant-spin-container {
                    height: 100%;
                }
            }

            .form-advertiser {
                height: 100%;
                .form-container {
                    @include flexbox();
                    flex-direction: column;
                    height: 100%;

                    .form-body {
                        @include flexbox();
                        flex-wrap: wrap;
                        gap: 0 24px;

                        .form-item-checkbox {
                            width: calc(100% * (1 / 3) - 24px);
                            .ant-form-item {
                                width: 100%;
                            }
                            .ant-form-item:first-child {
                                margin-bottom: 0;
                            }
                        }
                        .ant-form-item {
                            width: calc(100% * (1 / 3) - 24px);
                        }

                        .ant-picker-range {
                            width: 100%;
                        }

                        .ant-form-item-tooltip {
                            position: absolute;
                            right: 0;
                        }

                        .form-tooltip {
                            .ant-form-item-label > label {
                                width: 100%;
                            }
                        }
                    }

                    .footer-text {
                        color: $gray;
                    }

                    .upload-list-inline {
                        .ant-upload-list-item {
                            margin-top: 0;
                        }
                    }

                    .buttons-footer {
                        height: 100%;
                        @include flexbox();
                        @include align-items(flex-end);
                        @include justify-content(end);
                    }
                }
            }
            .confirmation-advertiser {
                @include flexbox();
                flex-direction: column;
                height: 100%;

                .confirmation-data {
                    @include flexbox();
                    flex-direction: column;
                    gap: 24px;
                    .description {
                        font-size: $h4-font-size;
                        font-weight: $font-weight-semi-bold;
                    }
                    .logo-name {
                        @include flexbox();
                        @include align-items(center);
                        gap: 10px;
                    }

                    .title-description {
                        @include flexbox();
                        flex-direction: column;
                        gap: 4px;
                        .title {
                            font-size: $h5-font-size;
                            color: $gray-dark-2;
                        }
                    }
                }

                .buttons-footer {
                    height: 100%;
                    @include flexbox();
                    @include align-items(flex-end);
                    @include justify-content(end);
                }
            }
        }
    }
}

@include md-max {
    #add-advertiser {
        .right-container {
            .body {
                .form-advertiser {
                    .form-container {
                        .form-body {
                            .form-item-checkbox {
                                width: calc(100% * (1 / 2) - 24px);
                                .ant-form-item {
                                    width: 100%;
                                }
                            }
                            .ant-form-item {
                                width: calc(100% * (1 / 2) - 24px);
                            }
                        }
                    }
                }
            }
        }
    }
}

@include sm-max {
    #add-advertiser {
        .right-container {
            .body {
                .form-advertiser {
                    .form-container {
                        .form-body {
                            .form-item-checkbox {
                                width: 100%;
                                .ant-form-item {
                                    width: 100%;
                                }
                            }
                            .ant-form-item {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
