#image-cropper {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    width: -webkit-fill-available;
    gap: 5px;

    .uploader-preview-img {
        border: 1px solid $preview-border;
        width: 40px;
        height: 40px;
        border-radius: 2px;

        img {
            width: 38px;
            height: 38px;
            border-radius: 2px;
        }
    }

    .delete-button {
        span:hover {
            color: $danger;
            cursor: pointer;
        }
    }

    .button-uploader {
        padding: 7px 17px 6px 18px;
        @include border-radius(3px);
        @include border(solid, 1px, $light-grey);
        background-color: $white;
        -webkit-touch-callout: none;
        /* iOS Safari */
        -webkit-user-select: none;
        /* Safari */
        -khtml-user-select: none;
        /* Konqueror HTML */
        -moz-user-select: none;
        /* Old versions of Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
        user-select: none;
        height: 40px;
        width: 100%;
        text-align: center;
        font-size: 16px;
        white-space: nowrap;

        span {
            margin-left: 10px;
        }

        &:hover {
            @include border(solid, 1px, $info-hover);
            cursor: pointer;
        }
    }

    .uploader-disabled {
        background-color: $light;
        color: $disable-text-color;


        &:hover {
            @include border(solid, 1px, $light-grey);
            cursor: not-allowed;
        }
    }
}

#modal-cropper-container {
    min-height: 400px;
    position: relative;
}

#cropper-slider {
    position: absolute;
    bottom: 0;
    width: 100%;

    .rs-tooltip {
        display: none !important;
    }
}

.reactEasyCrop_Container {
    margin: 10px;
}