#metrics-page {
    @include flexbox();
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;

    .box {
        background-color: $white;
        @include border-radius(2px);
        padding: 24px;

        >.title {
            font-size: $h4-font-size;
        }
    }

    .data-empty {
        color: $gray-hover;
    }

    .container-charts {
        @include flexbox();
        gap: 24px;
        width: 100%;

        .box-chart {
            background-color: $white;
            @include border-radius(2px);
            width: 100%;
            position: relative;

            >.title {
                font-size: $h4-font-size;
                padding: 16px 24px;
                border-bottom: 1px solid $light-gray;
            }

            >.body {
                padding: 24px;
                min-height: 200px;

                .data-ant-empty {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

            }
        }

        .box-chart:first-child {
            width: 50%;
        }

        .box-chart:only-child {
            width: 100%
        }

    }

    .header {
        @include flexbox();
        @include justify-content(space-between);

        .title {
            font-size: $h2-font-size;
            font-weight: $font-weight-semi-bold;
        }

        .campaign-title {
            font-size: $h5-font-size;
            color: $gray;
            margin-bottom: 5px;
        }

        .campaign-data {
            font-size: $h1-font-size;
            font-weight: $font-weight-semi-bold;
        }

        .campaign-box {
            @include flexbox();
            gap: 10px;
        }
    }

    .filter-metrics {
        #form-metrics {
            .form-container {
                @include flexbox();
                @include align-items(center);
                @include justify-content(center);

                .form-body {
                    @include flexbox();
                    flex-wrap: wrap;
                    gap: 0 8px;
                    @include align-items(flex-end);
                    width: 100%;

                    .ant-form-item {
                        min-width: 250px;
                        margin-bottom: 10px;

                        .ant-form-item-label {
                            padding: 0;

                            label {
                                height: 30px;
                            }
                        }

                        .ant-form-item-control {
                            .ant-form-item-control-input {

                                min-height: 24px;
                            }

                            .ant-form-item-control-input-content {
                                width: 250px;
                            }
                        }
                    }



                    .ant-form-item-tooltip {
                        position: absolute;
                        right: 0;
                    }

                    .form-tooltip {
                        .ant-form-item-label>label {
                            width: 100%;
                        }
                    }
                }

                .buttons {
                    @include flexbox();
                    @include align-items(flex-start);
                    flex-direction: row;

                    .separador-vertical {
                        height: 24px;
                        width: 1px;
                        background-color: $light-gray;
                        margin-left: 8px;
                        margin-right: 16px;
                        margin-bottom: 10px;
                    }


                }
            }
        }
    }

    .general-metrics {
        @include flexbox();
        @include justify-content(space-around);
        @include align-items(stretch);
        gap: 10px;
        height: auto;


        .box-container {
            background-color: $white;
            padding: 24px;
            @include flexbox();
            @include flex-column();
            @include justify-content(space-between);
            @include border-radius(2px);
            position: relative;
            width: 100%;
            border: 1px solid $disabled-gray-color;


            .box-container-addon-bar {
                background-color: $menu-active;
                width: 4px;
                height: 100%;
                border-radius: 2px 0 0 2px;
                position: absolute;
                top: 0;
                left: 0;
            }

            .box-container-header {
                @include flexbox();
                @include justify-content(space-between);
                @include align-items(flex-start);
                white-space: nowrap;

                .box-container-title {
                    font-size: $h3-font-size;
                    font-weight: $font-weight-medium;
                    color: $gray-dark;
                    margin-right: 10px;
                }
            }

            .box-container-body {
                .box-container-data {
                    font-size: $h1-font-size;
                    font-weight: $font-weight-semi-bold;
                    color: $black;
                    margin-top: 10px;

                }
            }

            .box-container-footer {

                .box-container-divisor {
                    width: 100%;
                    margin: 10px 0;
                    border-bottom: 1px solid $chinese-white;
                }

                .box-container-footer-text {
                    font-size: $h6-font-size;
                    font-weight: $font-weight-normal;
                    color: $gray;
                }
            }
        }
    }
}

@include md-max {
    #metrics-page {


        .general-metrics {


            .box-container {
                padding: 10px 15px;


            }

        }
    }
}

@include sm-max {
    #metrics-page {
        .container-charts {
            .box-chart:first-child {
                width: 100%;
            }

        }

        .general-metrics {
            flex-wrap: nowrap;
            overflow-x: auto;
            width: auto;
            @include justify-content(unset);

            .box-container {
                padding: 10px 10px;
                width: auto;

                .box-container-header {
                    .box-container-title {
                        font-size: $h4-font-size;
                        margin-right: 5px;
                    }

                    .box-container-time {
                        font-size: $h6-font-size;

                    }
                }
            }

        }
    }
}

@include xs-max {
    #metrics-page {
        .header {
            flex-direction: column;

            .title {
                margin-bottom: 10px;
            }
        }

        .container-charts {
            flex-direction: column;

            .box-chart:first-child {
                width: 100%;
            }

        }

        .general-metrics {
            flex-wrap: nowrap;
            overflow-x: auto;
            width: auto;
            @include justify-content(unset);
        }
    }
}