body {
    font: {
        family: $font-family-base;
        size: $font-size-base;
        weight: $font-weight-base;
    }

    line-height: $line-height-base;
    color: $dark-gray;
    word-break: break-word;

    background: {
        color: $body-bg;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font: {
            weight: $font-weight-medium;
        }
    }

    h1 {
        font-size: $h1-font-size;
    }

    h2 {
        font-size: $h2-font-size;
    }

    h3 {
        font-size: $h3-font-size;
    }

    h4 {
        font-size: $h4-font-size;
    }

    h5 {
        font-size: $h5-font-size;
    }

    h6 {
        font-size: $h6-font-size;
    }

    .switch-container {
        padding: 48px 0 0 0;

        .switch-context {
            margin: 24px;
            overflow-x: hidden;
        }
    }

    .spin-general {
        position: fixed !important;
        height: 100% !important;
        max-height: 100% !important;
    }

    .spin-local {
        height: 100% !important;
        max-height: 100% !important;
    }

    .btn-big {
        height: 40px;
        background-color: $primary;
        border: 1px solid $primary;
        color: $deep-purple;
        box-shadow: 0px 3px 6px #00000029;
    }

    .btn-big:hover {
        background-color: $primary-color-hover;
        border: 1px solid $primary-color-hover;
        color: $deep-purple;
    }

    .btn-ghost {
        color: $deep-purple !important;
        border-color: $deep-purple !important;
    }

    .btn-ghost:active:hover,
    .btn-ghost:hover {
        color: $deep-purple-hover !important;
        border-color: $deep-purple-hover !important;
    }

    .btn-actions {
        height: 24px;
        @include flexbox();
        @include align-items(center);
        border-radius: 2px;
        border-color: #cfccd8;
        color: $deep-purple;
    }

    .btn-cancel {
        width: 108px;
        background-color: $white;
        border: 1px solid $light-gray;
        @include border-radius(4px);
        font-size: $h5-font-size;
    }

    .btn-save {
        width: 108px;
        background-color: $primary;
        border: 1px solid $primary;
        @include border-radius(4px);
        font-size: $h5-font-size;
    }

    .btn-detail {
        min-width: auto;
        background-color: $primary;
        border: 1px solid $primary;
        @include border-radius(15px);
    }

    .btn-edit {
        min-width: 108px;
        background-color: $white;
        border: 1px solid $light-gray;
        @include border-radius(4px);
        font-size: $h5-font-size;
    }

    .dropdown-select-link button svg {
        color: $gray-dark-2;
    }

    .label-container-help a {
        margin-left: 16px;
    }

    .ant-form-item.load {
        .ant-select-selection-item {
            display: none;
        }
    }

    .ant-form-item-label > label {
        height: auto !important;
    }

    .ant-table-thead > tr > th {
        font-weight: $font-weight-semi-bold !important;
    }

    .ant-dropdown-menu-item {
        color: $gray;
    }

    .help-text {
        color: $info;
        font-size: calculateRem(13px);
    }

    .show-img {
        width: 40px;
        height: 40px;
    }

    @include xs {
        .switch-container {
            padding: 48px 0 0 48px;
        }
    }

    .asset-info-modal {
        .ant-modal-content {
            .ant-modal-header .header {
                @include flexbox();
                gap: 10px;
                @include align-items(center);

                .info .name {
                    font-size: $h6-font-size;
                    font-weight: $font-weight-normal;
                    color: $gray-dark-2;
                }
            }
            .ant-modal-body .body {
                @include flexbox();
                gap: 10px;
                height: 300px;

                .image {
                    width: 50%;

                    .ant-carousel {
                        height: 300px;

                        .image-container {
                            background-color: $light;
                            height: 300px;
                            text-align: center;
                            display: flex !important;
                            @include align-items(center);
                            @include justify-content(center);

                            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNTQiIGhlaWdodD0iNTQiDQogICAgdmlld0JveD0iMCAwIDU0IDU0Ij4NCiAgICA8ZGVmcz4NCiAgICAgICAgPGNsaXBQYXRoIGlkPSJhIiBmaWxsPSJub25lIj4NCiAgICAgICAgICAgIDxyZWN0IGNsYXNzPSJhIiBmaWxsPSJub25lIiB3aWR0aD0iNTQiIGhlaWdodD0iNTQiIC8+DQogICAgICAgIDwvY2xpcFBhdGg+DQogICAgPC9kZWZzPg0KICAgIDxyZWN0IGNsYXNzPSJhIiBmaWxsPSJub25lIiB3aWR0aD0iNTQiIGhlaWdodD0iNTQiIC8+DQogICAgPGcgY2xhc3M9ImIiIGNsaXAtcGF0aD0idXJsKCNhKSI+DQogICAgICAgIDxyZWN0IGNsYXNzPSJhIiBmaWxsPSJub25lIiB3aWR0aD0iNTQiIGhlaWdodD0iNTQiIC8+DQogICAgICAgIDxwYXRoIGNsYXNzPSJjIiBmaWxsPSIjOGM4YzhjIg0KICAgICAgICAgICAgZD0iTTQ1LjU2MywzNy4xMjRIMS42ODdBMS42ODksMS42ODksMCwwLDEsMCwzNS40MzhWMS42ODdBMS42ODksMS42ODksMCwwLDEsMS42ODcsMEg0NS41NjNhMS42ODksMS42ODksMCwwLDEsMS42ODcsMS42ODdWMzUuNDM4QTEuNjg5LDEuNjg5LDAsMCwxLDQ1LjU2MywzNy4xMjRaTTE0Ljk1MiwxOS4yNDVhLjQxOC40MTgsMCwwLDAtLjMyNC4xNUw1LjY0OCwzMC4wNDlhLjQzNy40MzcsMCwwLDAtLjEuMjczLjQyMi40MjIsMCwwLDAsLjQyMS40MjFINDEuMjc2YS40MjUuNDI1LDAsMCwwLC4yNzMtLjEuNDMyLjQzMiwwLDAsMCwuMDYzLS41OTFMMjkuNSwxNS43M2EuMzQxLjM0MSwwLDAsMC0uMDM2LS4wMzdsLS4wMTctLjAxNmEuNDI2LjQyNiwwLDAsMC0uMjcyLS4xLjQxOC40MTgsMCwwLDAtLjMyNC4xNWwtOC4zMzcsOS44ODhMMTUuMjc3LDE5LjRhLjM0MS4zNDEsMCwwLDAtLjAzNi0uMDM3bC0uMDE3LS4wMTZBLjQyNi40MjYsMCwwLDAsMTQuOTUyLDE5LjI0NVptLS41LTExLjY1MmEzLjM3NSwzLjM3NSwwLDEsMCwzLjM3NiwzLjM3NkEzLjM3OCwzLjM3OCwwLDAsMCwxNC40NDgsNy41OTRaIg0KICAgICAgICAgICAgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMy4zNzUgOC40MzgpIiAvPg0KICAgIDwvZz4NCjwvc3ZnPg==);
                            background-size: 54px;
                            background-position: center;
                            background-repeat: no-repeat;

                            .img-default {
                                pointer-events: none;
                                user-select: none;
                            }

                            .img-gallery {
                                width: 100%;
                                transition: filter 333ms;
                                filter: brightness(1);
                                position: relative;
                            }

                            .img-gallery:hover {
                                cursor: pointer;
                                filter: brightness(0.8);
                            }
                        }

                        .slick-dots-bottom {
                            bottom: -22px;
                        }

                        .slick-dots li button {
                            background: #e8e8e8;
                            opacity: 1;
                        }

                        .slick-dots li.slick-active button {
                            background: #bfbfbf;
                        }
                    }
                }
                .table {
                    width: 50%;
                    overflow-y: auto;

                    .ant-descriptions-item-label {
                        font-weight: 600;
                        word-break: initial;
                    }
                }
            }

            .ant-modal-footer {
                padding: 16px 16px 21px 16px;
            }
        }
    }

    #reference-map-modal {
        @include flexbox();
        gap: 16px;
        flex-direction: column;
        font-size: $h6-font-size;

        .block {
            border: 1px solid $preview-border;
            .header {
                border-bottom: 1px solid $preview-border;
                padding: 12px;
                font-weight: $font-weight-semi-bold;
            }
            .body {
                padding: 12px;
            }

            .body.assets {
                @include flexbox();
                flex-direction: column;
                gap: 20px;

                .material {
                    @include flexbox();
                    gap: 15px;

                    .digital,
                    .impreso {
                        @include flexbox();
                        flex-direction: column;
                        gap: 5px;
                    }
                    .title {
                        font-weight: $font-weight-semi-bold;
                    }
                    .material-body {
                        @include flexbox();
                        flex-direction: column;
                        gap: 5px;
                    }
                    .material-body div img {
                        width: 12px;
                        margin-right: 5px;
                    }

                    .separador {
                        border-left: 1px solid $light-gray;
                    }
                }

                .type {
                    .type-title {
                        font-weight: $font-weight-semi-bold;
                    }
                    .type-body {
                        @include flexbox();
                        @include justify-content(space-between);
                    }

                    .type-box .value {
                        font-weight: $font-weight-semi-bold;
                    }

                    .separador {
                        border-left: 1px solid $light-gray;
                    }
                }
            }
            .body.poi {
                @include flexbox();
                flex-wrap: wrap;
                gap: 10px;
                .poi-item {
                    @include flexbox();
                    @include align-items(center);
                    gap: 5px;
                    width: 140px;
                    .icon {
                        @include flexbox();
                        @include justify-content(center);
                        @include align-items(center);
                    }
                }
            }

            .body.ots {
                color: $gray;

                .scale {
                    @include flexbox();
                    @include justify-content(center);
                    @include align-items(center);
                }
            }

            .body.example {
                .title {
                    font-weight: $font-weight-semi-bold;
                    margin-bottom: 12px;
                }
                .container {
                    @include flexbox();
                    @include align-items(center);
                    gap: 5px;
                }
            }
        }
    }

    .seleccion-modal {
        .ant-modal-header {
            background-color: $account-gray;
            border-color: $account-gray-border;
        }

        .ant-modal-body {
            background-color: $account-gray;

            .body-table {
                margin: 20px 0;
            }

            .cost-container {
                width: 300px;
                background-color: $white;
                border: 1px solid $light-gray;
                @include flexbox();
                flex-direction: column;
                margin: 0 0 0 auto;

                .header,
                .footer {
                    padding: 24px;
                    @include flexbox();
                    flex-direction: column;
                    gap: 8px;

                    .row {
                        @include flexbox();
                        @include justify-content(space-between);
                        @include align-items(flex-end);
                        font-size: $h5-font-size;

                        .title {
                            color: $gray;
                        }

                        .value {
                            color: $dark-gray;
                        }
                    }
                }

                .header {
                    border-bottom: 1px solid $light-gray;

                    .row {
                        .title {
                            color: $gray;
                        }

                        .value {
                            color: $dark-gray;
                        }
                    }
                }

                .footer {
                    .row {
                        .title {
                            font-size: $h6-font-size;
                            color: $dark-gray;
                            font-weight: $font-weight-semi-bold;
                        }

                        .value {
                            color: $dark-gray;
                            font-size: $h1-font-size;
                        }
                    }
                }
            }
        }
    }

    #modal-advertiser {
        .body {
            @include flexbox();
            flex-direction: column;
            gap: 24px;
            .description {
                font-size: $h4-font-size;
                font-weight: $font-weight-semi-bold;
            }
            .logo-name {
                @include flexbox();
                @include align-items(center);
                gap: 10px;
            }

            .title-description {
                @include flexbox();
                flex-direction: column;
                .title {
                    font-size: $h5-font-size;
                    color: $gray-dark-2;
                }
            }

            .form-advertiser {
                height: 100%;
                .form-container {
                    @include flexbox();
                    flex-direction: column;
                    height: 100%;

                    .form-body {
                        @include flexbox();
                        flex-wrap: wrap;
                        gap: 0 24px;

                        .ant-form-item {
                            min-width: 150px;
                            max-width: 300px;
                            width: 100%;
                        }
                    }
                }
            }

            .footer {
                height: 100%;
                @include flexbox();
                @include align-items(flex-end);
                @include justify-content(end);
            }
        }
    }
}
