#slide-container {
    @include flexbox();
    @include align-items(center);
    overflow-y: auto;
    max-width: 100%;
    position: relative;
    height: 24px;

    button span {
        font-size: 8px;
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
    }

    .left {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 90;
        @include flexbox();
        @include justify-content(center);
        flex-direction: row;
    }

    .right {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 90;
        @include flexbox();
        @include justify-content(center);
        flex-direction: row;
    }

    // .left::after,
    // .right::before {
    //     height: 24px;
    //     width: 30px;
    //     content: "";
    //     background: linear-gradient(to right, white 20%, rgba(33, 33, 33, 0) 80%)
    // }

    // .right::before {

    //     transform: rotateZ(180deg);
    // }

    .items-container {
        @include flexbox();
        gap: 5px;
        overflow: hidden;
        margin: 0 28px 0;
        position: relative;

        .tag-item {
            background-color: $disabled-gray-color;
            padding: 2px 8px;
            border-radius: 16px;
            border: 1px solid $preview-border;
            color: $gray-dark-2;
            font-size: $h6-font-size;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            white-space: nowrap;
        }
    }

    .items-container::-webkit-scrollbar {
        display: none;
    }
}
