#preview-advertiser {
    background-color: $white;
    @include border-radius(2px);
    min-height: calc(100vh - 178px);
    max-height: calc(100vh - 178px);
    @include flexbox();
    flex-direction: column;
    position: relative;

    .box-container {
        margin: 24px;
        background-color: $account-gray;
        border: 1px solid $account-gray-border;
        @include flexbox();
        flex-direction: column;
        height: calc(100vh - 226px);

        .header {
            padding: 16px 24px;
            border-bottom: 1px solid $account-gray-border;
            @include flexbox();
            gap: 16px;
            @include align-items(center);
            .title {
                font-size: $h4-font-size;
            }
        }

        .body {
            margin: 24px;
            background-color: $white;
            padding: 24px;
            height: 100%;

            @include flexbox();
            flex-direction: column;
            gap: 24px;
            .description {
                font-size: $h4-font-size;
                font-weight: $font-weight-semi-bold;
            }
            .logo-name {
                @include flexbox();
                @include align-items(center);
                gap: 10px;
            }

            .title-description {
                @include flexbox();
                flex-direction: column;
                gap: 4px;
                .title {
                    font-size: $h5-font-size;
                    color: $gray-dark-2;
                }
            }

            .form-preview-advertiser {
                height: 100%;
                .form-container {
                    @include flexbox();
                    flex-direction: column;
                    height: 100%;

                    .form-body {
                        @include flexbox();
                        flex-wrap: wrap;
                        gap: 0 24px;

                        .ant-form-item {
                            min-width: 150px;
                            max-width: 300px;
                            width: 100%;
                        }
                    }
                }
            }

            .footer {
                height: 100%;
                @include flexbox();
                @include align-items(flex-end);
                @include justify-content(end);
            }
        }
    }
}
