.backdrop {
    background-color: $backdrop;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    @include transition(all 500ms ease);
    opacity: 0;
    z-index: 100;
    visibility: hidden;
}

.backdrop.active {
    opacity: 1;
    visibility: visible;
}

#sidebar {
    position: fixed;
    top: 0;
    left: -208px;
    width: 48px;
    height: 100vh;
    overflow: hidden;
    background-color: $deep-purple;
    @include transition(all 500ms ease);
    @include flexbox();
    flex-direction: column;
    z-index: 101;
    @include justify-content(space-between);

    .logo-tdt {
        width: 100%;
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        margin-bottom: 24px;

        .logo {
            background-image: url('../../img/TDT-Logo-Mobile.svg');
            background-repeat: no-repeat;
            background-size: auto;
            background-position: center;
            width: 100%;
            height: 24px;
        }

    }

    .menu {
        margin-top: 48px;

        a {
            color: $white;
        }

        .menu-item {
            height: 40px;
            margin-bottom: 4px;
            width: 100%;
            @include flexbox();
            position: relative;
            @include align-items(center);



            span {
                svg {
                    font-size: $h4-font-size;
                }
            }

            .item {
                @include flexbox();
                @include align-items(center);
                padding: 0 20px;

                .text-menu {
                    margin-left: 10px;
                }
            }
        }

        .menu-item-active {
            color: $deep-purple !important;

            span {
                background-color: $menu-active;
            }

            .selected {
                background-color: $celine;
                width: 4px;
                height: 100%;
                border-radius: 0px 4px 4px 0px;
                position: absolute;
            }
        }

        .menu-item:hover {
            span {
                color: $deep-purple !important;
                background-color: $menu-hover;
            }

            background-color: $menu-hover;
        }

        .menu-item.menu-item-active {
            background-color: $menu-active;
        }
    }

}

#sidebar.active {
    left: 0;
    max-width: 208px;
    min-width: 208px;
    width: 208px;
}

@include xs {
    .backdrop {
        display: none;
    }

    #sidebar {
        position: fixed;
        top: 0;
        left: 0;
        max-width: 48px;
        min-width: 48px;
        width: 48px;
        height: 100vh;

        .logo-tdt {


            .logo {
                background-image: url('../../img/TDT-Logo-Desktop.svg');
            }
        }

        .menu {
            .menu-item {
                .item {
                    padding: 0;
                    margin: auto;

                    .text-menu {
                        display: none;
                    }
                }
            }
        }
    }

    #sidebar.active {
        left: 0;
        max-width: 48px;
        min-width: 48px;
        width: 48px;
    }
}