#navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 48px;
    background-color: $white;
    @include flexbox();
    flex-direction: row;
    @include justify-content(space-between);
    @include align-items(center);
    z-index: 100;
    @include box-shadow(0px 3px 6px $box-shadow-color);
    margin-left: 0;


    .icon-navbar {
        font-size: $h4-font-size;
        color: $black;
    }

    .left {
        @include flexbox();
        @include align-items(center);
        gap: 10px;
        padding: 0 16px;


        .logo img {
            width: 61px;
        }
    }

    .right {
        @include flexbox();
        @include align-items(center);
        color: $black;
        padding: 0 16px;

        .rigth-item {
            min-width: 40px;
            height: 48px;
            @include flexbox();
            @include align-items(center);
            @include justify-content(center);
            cursor: pointer;
        }

        .bell {
            .badge-bell sup {
                top: -0.3rem;
            }
        }

        .user {
            padding: 0 12px;

            .icon {
                background-color: $primary;
                @include border-radius(100%);
                width: 18px;
                height: 18px;
                @include flexbox();
                @include align-items(center);
                @include justify-content(center);

                .icon-navbar {
                    font-size: $h5-font-size;
                }
            }

            .username {
                display: none;
            }

        }

        .rigth-item:hover {
            background-color: $hover-menu;
        }
    }

}

@include xs {
    #navbar {
        padding-left: 48px;

        .button-toggle {
            display: none;
        }

        .right {
            .user {
                .username {
                    display: block;
                    margin-left: 8px;
                }

            }
        }
    }
}