#body-container {
    background-color: $white;
    @include border-radius(2px);

    .component-visible {
        animation: change 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    @keyframes change {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    .strategy-container {
        padding: 24px;
    }

    > div {
        @include flexbox();
        gap: 24px;
    }

    .body-left {
        width: 270px;
        position: absolute;
        left: -270px;
        @include transition(all 333ms ease-in-out);

        .filter {
            position: relative;
            visibility: hidden;
            @include transition(all 333ms ease-in-out);

            .title {
                padding: 16px 24px;
                border-bottom: 1px solid #e8e8e8;
                font-size: $h4-font-size;
                font-weight: $font-weight-semi-bold;
            }

            .filter-form {
                padding: 16px 24px;

                #form-filter {
                    .form-container {
                        @include flexbox();
                        @include align-items(center);
                        @include justify-content(center);

                        .form-body {
                            @include flexbox();
                            flex-wrap: wrap;
                            gap: 0 8px;
                            @include align-items(flex-end);
                            width: 100%;
                            @include justify-content(space-evenly);

                            .numbers {
                                @include flexbox();
                                gap: 10px;
                            }

                            .ant-form-item {
                                width: 100%;
                                margin-bottom: 8px;

                                .ant-form-item-label {
                                    padding: 0;

                                    label {
                                        height: 30px;
                                    }
                                }

                                .ant-form-item-control {
                                    .ant-form-item-control-input {
                                        min-height: 24px;
                                    }
                                }
                            }

                            .ant-form-item-tooltip {
                                position: absolute;
                                right: 0;
                            }

                            .form-tooltip {
                                .ant-form-item-label > label {
                                    width: 100%;
                                }
                            }
                        }

                        .buttons {
                            @include flexbox();
                            @include align-items(center);
                            flex-direction: row;
                            margin-top: 20px;
                        }
                    }
                }
            }

            .button-collapse {
                position: absolute;
                right: -1px;
                top: 50%;
                visibility: visible;
                @include translate(100%, -50%);

                button {
                    @include flexbox();
                    @include align-items(center);
                    @include justify-content(center);
                    background-color: $info;
                    color: $white;
                    height: 32px;
                    width: 23px;
                    @include box-shadow(0px 3px 6px #00000029);
                    @include border-radius(0px 2px 2px 0px);

                    span {
                        font-size: $h5-font-size;
                        @include flexbox();
                        @include align-items(center);
                        @include justify-content(center);
                        transition: all 333ms ease-in-out, color 333ms;
                    }

                    span.open {
                        @include rotate(-180deg);
                    }
                }
            }
        }
    }

    .body-left.open {
        left: 0;

        .filter {
            visibility: visible;
        }
    }

    .body-right.open {
        margin-left: 0;
        border-color: transparent;
        width: 100%;
    }

    .body-right {
        width: calc(100% - 270px);
        margin-left: 270px;
        padding: 0 0 0 24px;
        border-left: 1px solid #e8e8e8;
        transition: margin 333ms ease-in-out, width 333ms ease-in-out;
        min-height: 650px;

        .header {
            padding: 20px 10px;
            background-color: $white;
            width: 100%;
            @include flexbox();
            @include align-items(center);

            gap: 5px;

            .align-right {
                @include flexbox();
                @include align-items(center);
                margin-left: auto;
            }

            .ant-divider-vertical {
                height: 24px;
            }
        }

        .assets-list {
            padding: 0 10px 10px 10px;
        }

        .map {
            padding: 0 10px 10px 10px;
            position: relative;

            .mapboxgl-ctrl-logo,
            .mapboxgl-ctrl-attrib {
                display: none;
            }

            .layers-container {
                z-index: 1;
                position: absolute;
                top: 0;
                left: 0;
                margin: 16px 26px;
                width: 255px;

                .layers-button {
                    background-color: $white;
                    color: $black;
                    border-radius: 4px;
                    padding: 6px 12px;
                    border: 1px solid $preview-border;
                    height: 40px;
                    box-shadow: 0px 3px 6px #00000029;
                    @include flexbox();
                    @include align-items(center);
                    @include justify-content(space-between);
                    transition: 333ms all;

                    span {
                        font-size: 10px;
                    }
                }

                .layers-button:hover {
                    color: $info-hover;
                    cursor: pointer;
                }

                .layers-menu {
                    margin-top: 2px;
                    background-color: $white;
                    color: $black;
                    border-radius: 4px;
                    border: 1px solid $preview-border;
                    box-shadow: 0px 3px 6px $box-shadow-color;
                    transition: opacity 333ms, visibility 333ms;
                    overflow-x: hidden;
                    overflow: auto;
                    max-height: 580px;

                    .block {
                        .title {
                            padding: 10px;
                            font-weight: $font-weight-semi-bold;
                            border-bottom: 1px solid $preview-border;
                        }

                        .body {
                            padding: 12px;

                            .pois-box {
                                padding-right: 16px;

                                .left {
                                    @include flexbox();
                                    @include align-items(center);
                                    gap: 5px;

                                    span {
                                        color: $gray;
                                    }
                                }
                            }

                            .empty-layers {
                                color: $gray;
                            }

                            .ant-collapse .ant-collapse-item {
                                .ant-collapse-header {
                                    padding: 10px 0;
                                    padding-left: 20px;
                                    color: $black;

                                    span {
                                        padding: 10px 0;
                                        left: 0;
                                        font-size: $h5-font-size;
                                    }
                                }

                                .ant-collapse-content-box {
                                    p {
                                        margin-bottom: 5px;
                                    }
                                }
                            }

                            .item {
                                width: 100%;
                                @include flexbox();
                                @include align-items(center);
                                @include justify-content(space-between);
                                color: $gray-dark-2;
                                padding: 8px 0;
                            }
                        }
                    }
                }

                .layers-menu.open {
                    opacity: 1;
                    visibility: visible;
                    max-height: 580px;
                }

                .layers-menu.close {
                    opacity: 0;
                    visibility: hidden;
                    max-height: 0;
                }
            }

            .references {
                z-index: 1;
                position: absolute;
                bottom: 0;
                left: 0;
                margin: 20px 26px;
                background-color: $white;
                color: $black;
                border-radius: 4px;
                border: 1px solid $preview-border;
                padding: 2px 8px;
                box-shadow: 0px 3px 6px $box-shadow-color;
            }
            .references:hover {
                color: $info-hover;
                cursor: pointer;
            }

            .map-container {
                height: 690px;
            }
        }
    }
}
