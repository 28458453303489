$primary: #83d5d5;
$primary-color-hover: #a1dede;
$primary-color-active: #a1dede;
$primary-light-color: #85d6d6;
$primary-dark-color: #2e8989;

$primary-hover-notification: #eef6f6;
$primary-selected: #dcf1f1;

$blue-1: #e6f7ff;

$box-shadow-color: #00000029;
$backdrop: #00000063;
$deep-purple: #13023e;
$deep-purple-hover: #423464;
$black: #000000;
$hover-menu: #fafafa;
$light-gray: #e8e8e8;
$gray: #8c8c8c;
$disabled-border-color: #8c8c8c;
$gray-dark: #262626;
$gray-dark-2: #595959;
$gray-hover: #bfbfbf;
$account-gray: #f0f2f5;
$account-gray-hover: #ebeff5;
$account-gray-border: #e1e9f5;
$preview-border: #d9d9d9;

$selected-assets: #f0f5ff;

$disable-text-color: #00000040;

$background-login: #fafafa;

$info: #1890ff;
$info-hover: #40a9ff;
$secondary: #b7cfea;
$danger: #d83c31;
$success: #52c41a;
$warning: #f49537;
$disabled-gray-color: #f2f2f2;
$row-error-color: #f8d7da;
$circle-icon: #83e852;
$circle-stroke: #138d62;

$danger-background: #fd4d4f1a;
$danger-border: #fd4d4f33;

$success-background: #f6ffed;
$success-border: #b7eb8f;

$white: #ffffff;
$light-grey: #d6d6d6;
$black-with-transparencies: #00000029;
$light: #f5f5f5;
$light-medium: #919191;
$raven: #656d7d;
$dark-gray: #262626;
$light-blue: #57c3fc;
$blue: #5287bf;
$vivid-blue: #0072ec;
$light-cyan: #d9eafc;
$chinese-white: #e0e0e0;
$ebony: #2b303b;
$gray-scheduler: #f7f7f7;
$hold-color: #d96d00;
$proposal-color: #8c008c;
$campaign-color: #66cccc;
$disable-color: #5e5e5e;
$available-color: #e6e6e6;

$hold-disable-color: #b55b00;
$proposal-disable-color: #6e146e;
$campaign-disable-color: #5264b4;
$disable-disable-color: #3b4752;
$availabl-disable-color: #8e8e93;

$filter-poi-color: #f6f8fa;
$data-empty-color: #2c2c2c59;
$loader-backdrop-color: #ffffff66;
$rs-panel-title: #777777;

$bm-item-list: #b8b7ad;
$rs-pagination-btn: #4e4e4e;

$input-bg-addon: #f0f0f0;

$deep-purple: #13023e;
$celine: #7effff;
$menu-hover: #83d5d5;
$menu-active: #66cccc;
$violet: #744cd2;

$loader-dashboard: #e9ebec7a;
$divider-dashboard: #f2f2f5;
