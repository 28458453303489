#custom-range-picker-proposal {
    @include flexbox();
    gap: 10px;
    @include align-items(center);
    height: 19px;
    font-size: $h4-font-size;
    font-weight: 600;

    .form-field,
    .field {
        display: none;
    }

    .field {
        cursor: pointer;

        > div {
            display: flex;
            gap: 5px;
            align-items: center;
        }
    }

    .show {
        display: unset;
        animation: fade-in 0.5s;
    }

    @keyframes fade-in {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    .actions-buttons button span {
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        font-size: 10px;
    }
}
